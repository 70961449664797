import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { fetchStocks, fetchCities } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';
import request from 'helpers/request';
import StocksList from 'components/MyBoxes/StocksList';
import RejectedDocsModal from 'components/MyBoxes/RejectedDocsModal';
import NoItems from 'components/common/NoItems';
import Heading from 'components/UI/Heading';

class StockList extends Component {
	state = {
		isLoading: false,
		boxes: [],
		isModalOpen: false,
	};

	async componentDidMount() {
		const { isFetchedStocks, fetchStocks, user } = this.props;

		this.setState({ isLoading: true });

		if (!isFetchedStocks) {
			await fetchStocks();
		}

		if (user._id) {
			await this.fetchBoxes(user._id);
		}

		this.setState({ isLoading: false });

		if (!user.isKYCVerified) {
			this.showModal();
		}
	}

	fetchBoxes = async (id) => {
		const customerBoxes = await request('GET', `users/${id}/boxes`);
		this.setState({ boxes: customerBoxes });
	};

	showModal = () => {
		this.setState({ isModalOpen: true });
	};

	hideModal = () => {
		this.setState({ isModalOpen: false });
	};

	render() {
		const { boxes, isLoading, isModalOpen } = this.state;
		const { stocks, languageSchema, user } = this.props;

		if (!this.props.user) {
			return <Redirect to={{ pathname: '/login' }} />;
		}

		return (
			<div>
				{ (
					<div
						className="container mt-4 mb-4 pt-3 px-5 text-center"
						style={{
							border: '1px solid #ffd200',
							background: 'white',
						}}
					>
						<h4>
							Уважаемые клиенты!
						</h4>
						<p>Доступ на склад с 09:00 до 21:00</p>
					</div>
				)}
				{ false &&
					<div
						className="container mt-4 mb-4 pt-3 px-5 text-center"
						style={{
							border: '1px solid #ffd200',
							background: 'white',
						}}
					>
						<h4>Информируем Вас о режиме работы центра хранения в праздничные дни:</h4>
						<p> 8 марта 2025г – выходной день.</p>
					</div>

				}

				{false && (
					<div
						className="container mt-4 mb-4 pt-3 px-5 text-center"
						style={{
							border: '1px solid #ffd200',
							background: 'white',
						}}
					>
						<h4>Уважаемые клиенты</h4>
						<p>

							В настоящее время доступ в ЦХ "Ярославское шоссе" ограничен из-за технических неполадок. Мы приносим извинения за доставленные неудобства и работаем над решением проблемы.
							Для получения  информации обратитесь к менеджеру. Благодарим за понимание!
						</p>
					</div>
				)}

				{isLoading ? (
					<div className="text-center">
						<i className="fas fa-2x fa-spinner fa-spin"></i>
					</div>
				) : (
					<div>
						{boxes.length === 0 ? (
							<div className="card">
								<Heading>{languageSchema.HomePage.myBoxes}</Heading>
								<NoItems
									linkTo={'/book'}
									linkText={languageSchema.BoxBooking.chooseABox}
								/>
							</div>
						) : (
							<StocksList
								stocks={stocks}
								languageSchema={languageSchema}
								user={user}
								boxes={boxes}
								fetchBoxes={this.fetchBoxes}
							/>
						)}
						{!user.isKYCVerified &&
							(user.selfieKYCRejectionReason ||
								user.passportKYCRejectionReason ||
								user.extraKYCRejectionReason ||
								user.addressKYCRejectionReason) && (
							<RejectedDocsModal
								isModalOpen={isModalOpen}
								setIsModalOpen={this.hideModal}
								user={user}
								languageSchema={languageSchema}
							/>
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = ({ stocks, common, auth }) => {
	return {
		stocks: stocks.data,
		isFetchedStocks: stocks.isFetched,
		languageSchema: getLanguageSchema(common.language),
		user: auth.me,
	};
};

const mapDispatchToProps = {
	fetchStocks,
	fetchCities,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockList);
